import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
      <SEO title="B2B sales is getting harder....." />

        <article className="article">
            <header className="header header--primary article__header">
                <div className="header__image">
                    <div className="header__bgimage" style={{backgroundImage:"url(/media/svg/landing-256x183_man-running.svg)"}}></div>
                </div>
                <div className="header__content">
                    <div className="container">
                        <div className="row">
                            <div className="header__inner col-sm-9 col-md-7 col-lg-6 col-lg-offset-1">
                                <Link to="/knowledge-centre/challenges/" className="header__back back-link">
                                    <span className="sr-only">Back to all articles</span>
                                    <i className="icon"></i>
                                </Link>
                                <div className="header__title">
                                    <h3>Challenges</h3>
                                    <h1>B2B sales is getting harder.....</h1>
                                </div>
                                <h3 className="header__caption">63% of sales people are now failing to meet or exceed quota. Are you performing?</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container" id="article-content">
                <div className="row">
                    <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                        <div className="rich-text">
                            <p>The global market for B2B sales jobs is about to go into a major transition, meaning that there will be a large numbers of sales people losing their jobs in the coming three to five years. Fortunately, you don’t have to be one of them.</p>
                            <p>According to the Harvard Business Review, sixty-three per cent of all sales people are under-performing, while CSO Insights reported that only 55.8 per cent of sales people meet or exceed their quota. Whichever measure you use, around half of all B2B sales people aren’t making the grade, and this trend means more and more job hopping.
                                <br/>
                            </p>
                            <p>According to Sales Hacker’s Sales Metrics Report 2016, the average tenure of a B2B sales person is now only 1.4 years (or 16.8 months), while less than five per cent of sales people stay on board for more than three years. This type of under-performance is unsustainable and will ultimately lead to career uncertainty for sales professionals.
                                <br/>
                            </p>
                            <p>SalesTribe can help you ensure that you are not in this under-performance cohort. Join today - it's free!</p>
                            <p>
                                <br/>
                            </p>
                            <p>
                                <br/>
                            </p>
                            <p></p>
                            <p>
                                <br/>
                            </p>
                        </div>
                    </div>
                    <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
                        <div className="article__sidebar-wrap" id="article-sidebar">
                            <h3>Are you ready to make a change?</h3>
                            <div className="article__sidebar-btns">
                                <a target="_self" href="https://community.salestribe.com/" className="btn btn-default">Join for Free!</a>
                            </div>
                            <nav className="social-nav">
                                <h4>Share</h4>
                                <ul>
                                    <li className="linkedin">
                                        <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/&amp;title=B2B%20sales%20is%20getting%20harder.....&amp;summary=B2B%20sales%20is%20getting%20harder.....&amp;source=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/" target="_blank" rel="noopener noreferrer">
                                            <span className="sr-only">LinkedIn</span>
                                            <span className="icon icon-linkedin"></span>
                                        </a>
                                    </li>
                                    <li className="twitter">
                                        <a href="https://twitter.com/intent/tweet/?text=B2B%20sales%20is%20getting%20harder.....&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/" target="_blank" rel="noopener noreferrer">
                                            <span className="sr-only">Twitter</span>
                                            <span className="icon icon-twitter"></span>
                                        </a>
                                    </li>
                                    <li className="googleplus">
                                        <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/" target="_blank" rel="noopener noreferrer">
                                            <span className="sr-only">Google+</span>
                                            <span className="icon icon-google-plus"></span>
                                        </a>
                                    </li>
                                    <li className="facebook">
                                        <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/" target="_blank" rel="noopener noreferrer">
                                            <span className="sr-only">Facebook</span>
                                            <span className="icon icon-facebook-f"></span>
                                        </a>
                                    </li>
                                    <li className="email">
                                        <a href="mailto:?subject=B2B%20sales%20is%20getting%20harder.....&amp;body=https%3A//salestribe.com/knowledge-centre/challenges/b2b-sales-getting-harder/" target="_blank" rel="noopener noreferrer">
                                            <span className="sr-only">Email</span>
                                            <span className="icon icon-envelope"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    </aside>
                </div>
            </div>
        </article>

        <div className="block block--base video" id="info-video">
            <div className="video__wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="video__image" data-video-uri="https://www.youtube.com/embed/Qy4D_PTLL2w?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#info-video .embed-responsive" data-video-parent="#info-video">
                                <div className="video__bgimage" style={{backgroundImage:"url(/media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-1162x655.jpg)"}}></div>
                                <div className="video__video">
                                    <div className="embed-responsive embed-responsive-16by9"></div>
                                </div>
                            </div>
                            <div className="video__icon play-icon">
                                <i className="icon"></i>
                            </div>
                            <div className="video__content">
                                <div className="row">
                                    <div className="video__inner col-md-offset-2 col-md-8">

                                        <h1>The new era for sales professionals.</h1>
                                        <div className="video__caption">
                                            <p className="h3">When buyers change how they buy, then sellers must also change how they sell.</p>
                                            <p>Graham Hawkins - SalesTribe Mentor</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="block block--base quote">
            <div className="container">
                <div className="row">
                    <div className="quote__wrap col-sm-12">
                        <blockquote>
                            <p>22% of all B2B sales people will be replaced by self-serve eCommerce by 2020</p>
                            <footer>Forrester Research</footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="block block--base buttons"> */}
        {/*     <div className="container text-center"> */}
        {/*         <h3 className="buttons__title"><strong>Not sure where to start?</strong></h3> */}
        {/*         <Link to="/enquiries/" className="btn btn-default">Join for Free!</Link> */}
        {/*     </div> */}
        {/* </div> */}
        <div className="block block--base block--ruledtop item-paging">
            <div className="container">
                <div className="row">
                    <Link to="/knowledge-centre/challenges/i-need-to-become-a-specialist/" className="item-paging__wrap col-sm-12">
                        <div className="row">
                            <div className="item-paging__image col-sm-12 col-md-offset-1 col-md-5 col-lg-offset-2 col-lg-4">
                                <img alt="SalesTribe" src="/media/svg/landing-256x183_man-playful.svg" width="420" />
                            </div>
                            <div className="item-paging__content col-sm-12 col-md-4">
                                <h3>Next challenge</h3>
                                <h2 className="item-paging__title">I need to become a specialist....</h2>
                                <h3 className="item-paging__caption">You can no longer add value to the buying process if you have &#39;general&#39; knowledge.</h3>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    </Layout>
)

export default IndexPage 
